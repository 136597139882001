<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="font-weight-medium text-h5">User Account Profile;</div>
        <div class="">
          <P
            >Below is the list of your information as captured in our
            database.</P
          >
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Data</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in profile_info" :key="item.name">
                <td>{{ item.data }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HTTP } from "../utilities/http-common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      profile_info: [],
    };
  },
  beforeMount() {
    HTTP.get("/ptms/v1.0/taxpayer/" + this.tp_taxpayerId)
      .then((response) => {
        this.profile_info = response.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapGetters(["tp_taxpayerId"]),
  },
};
</script>
