<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-img contain :src="require('@/assets/fctris.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="font-weight-medium text-h5">About This Project</div>
        <div class="py-5">
          <p></p>
          <P
            >A project submitted to the department of computer science, national
            open university of nigeria in partial fulfillment of the requirement
            for the award of masters of science in information technology.</P
          >
          <p>It is develop by</p>
          <ul>
            <li>EHIGIE, IZIEGBE BEATRICE</li>
            <li>NOU204004777</li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import PartnershipHero from "@/components/PartnershipHero";
export default {
  name: "Partnership",
  components: {
    //PartnershipHero,
  },
};
</script>
