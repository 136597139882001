<template>
  <v-container fluid class="green darken-2">
    <v-row no-gutters class="mx-auto" max-width="344">
      <v-col cols="12" xs="12" class="pa-12">
        <v-img
          src="../assets/logo-irs.png"
          aspect-ratio="1"
          class="yellow mx-auto pa-2"
          max-width="320"
          max-height="70"
        >
        </v-img>
      </v-col>
      <v-col cols="12" xs="12">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="headline green--text darken-1 text-center"
                >Log in to your account</v-list-item-title
              >
              <v-list-item-subtitle class="text-center"
                >Taxpayers Only</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pa-5">
              <v-col cols="12" xs="12" class="py-1 px-3">
                <v-alert dense type="error" v-model="loginError" dismissible>
                  There was a problem with your login.
                </v-alert>
              </v-col>
              <v-col cols="12" xs="12" class="py-1 px-3">
                <v-text-field
                  dense
                  v-model="form.phone"
                  :rules="rules.phone"
                  label="Phone number*"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  dense
                  v-model="form.taxpayerId"
                  :rules="rules.taxpayerId"
                  type="password"
                  label="Taxpayer ID*"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-btn
                  block
                  :loading="loading"
                  :disabled="loading"
                  @click="validate"
                  class="green lighten-1 white--text"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions class="py-6 grey lighten-4">
            <v-btn text block to="/login-admin">
              <span class="grey--text">You are Admin?</span>&nbsp;&nbsp; Login
              Here
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" class="mx-auto" max-width="344">
        <v-card class="mx-auto green darken-2" max-width="344" outlined>
          <v-card-actions class="py-5">
            <v-btn text block color="white"> Forgot your password? </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { HTTP } from "../utilities/http-common";
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    loading: false,
    loginError: false,
    errors: [],
    form: {
      taxpayerId: "PT100001",
      phone: "08042445703",
    },
    valid: true,
    rules: {
      phone: [
        (v) => !!v || "Phone numbers is required",
        (v) =>
          (v && v.length == 11) ||
          "Phone number must be equall to 11 characters",
      ],
      taxpayerId: [
        (v) => !!v || "Taxpayer ID is required",
        (v) =>
          (v && v.length == 8) || "Taxpayer ID must be equall to 8 characters",
      ],
    },
  }),

  methods: {
    ...mapMutations(["UPDATE_TAXPAYER"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const formData = new FormData();
        formData.append("phone", this.form.phone);
        formData.append("taxpayerId", this.form.taxpayerId);
        HTTP.post("/ptms/v1.0/taxpayer/login", formData)
          .then((response) => {
            this.loading = false;
            if (response.data.actionStatus === "Access granted") {
              var taxpayer = {
                taxpayerId: response.data.taxpayerId,
                fullNames: response.data.fullNames,
                remittancePeriod: response.data.remittance_period,
                taxPeriod: response.data.taxPeriod,
                taxLiability: response.data.tax_liability,
                businessCategory: response.data.businessCategory,
                phone: response.data.phone,
              };
              //window.console.log(taxpayer);
              this.update_taxpayer(taxpayer);
              this.$router.push("/taxpayer-dashboard");
            } else {
              this.loginError = true;
            }
          })
          .catch((e) => {
            this.loading = false;
            this.loginError = true;
            this.errors.push(e);
          });
      }
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    update_taxpayer: function (tp) {
      this.UPDATE_TAXPAYER(tp);
    },
  },

  computed: {
    ...mapGetters([
      "tp_taxpayerId",
      "remittancePeriod",
      "tp_taxLiability",
      "tp_businessCategory",
      "tp_phone",
    ]),
  },
};
</script>
