<template>
  <span>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="teal lighten-2"
      dark
      disable-resize-watcher
    >
      <v-list-item color="teal darken-1 yellow--text">
        <v-list-item-content color="teal darken-1 yellow--text">
          <v-list-item-title class="text-h6"> FCT-IRS PTMS </v-list-item-title>
          <v-list-item-subtitle> Taxpayer App </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.path"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="teal lighten-1 yellow--text" dark absolute flat app>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <a href="/admin-dashboard">
        <v-img
          class="mx-2"
          max-height="40"
          max-width="40"
          contain
          :src="require('@/assets/fctris.jpg')"
        >
        </v-img
      ></a>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-md-and-down white--text">
        <v-btn text class="white--text" to="/admin-dashboard">Dashboard</v-btn>
        <v-btn text class="white--text" to="/admin-payments"> Payments </v-btn>
        <v-btn text class="white--text" to="/admin-change-request"
          >Change Requests</v-btn
        >
        <v-btn text class="white--text" to="/admin-duplicate">Duplicates</v-btn>
        <v-btn text class="yellow--text" to="/logout"> LOGOUT </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </span>
</template>

<script>
export default {
  name: "TaxpayerNavbar",
  data() {
    return {
      appTitle: "Meal Prep",
      drawer: false,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/taxpayer-dashboard",
        },
        {
          title: "Taxpayer Profile",
          icon: "mdi-view-dashboard",
          path: "/taxpayer-profile",
        },
        {
          title: "Ledger",
          icon: "mdi-view-dashboard",
          path: "/taxpayer-ledger",
        },
        {
          title: "Logout",
          icon: "mdi-view-cancel",
          path: "/logout",
        },
      ],
    };
  },
};
</script>
<style scoped></style>
