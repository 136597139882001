<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="font-weight-medium text-h5">Duplicate Taxpayer List</div>
        <div class="">
          <P></P>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <template>
          <v-data-table
            :headers="headers"
            :items="table_data"
            :items-per-page="15"
            class="elevation-1"
          >
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HTTP } from "../utilities/http-common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      profile_info: [],
      headers: [
        {
          text: "FullNames",
          align: "start",
          sortable: false,
          value: "fullNames",
        },
        { text: "TaxpayerType", value: "taxpayerType" },
        { text: "Phone", value: "phone" },
        { text: "Business", value: "business" },
        { text: "Town", value: "town" },
        { text: "# Of Employees", value: "noOfEmployees" },
        { text: "Est. Yearly Turnover", value: "estimatedYearlyTurnover" },
      ],
      table_data: [],
    };
  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.table_data.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
  beforeMount() {
    HTTP.get("/ptms/v1.0/enumerator/duplicate")
      .then((response) => {
        this.table_data = response.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapGetters(["tp_taxpayerId"]),
  },
};
</script>
