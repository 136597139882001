<template>
  <v-app>
    <router-view name="navbar"></router-view>
    <v-main class="">
      <v-container fluid class="pa-0">
        <transition :name="fade">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
    <router-view name="footer"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
<style scoped></style>
