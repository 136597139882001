<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-img contain :src="require('@/assets/fctris.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="font-weight-medium text-h5">
          FCT-IRS Presumptive Tax Management System
        </div>
        <div class="py-5">
          <P
            >Presumptive Tax Management systems(PTMS) is a tax management system
            design to capture presumptive taxpayers, assess them and deduct
            taxes from them based on prevailing presumptive tax criteria for
            FCT-IR.</P
          >
          <p>
            It is design to capture tax payers who are do not fails into the
            formal sector. Therefore such taxpayer:
          </p>
          <ul>
            <li>May not have a bank account</li>
            <li>May not have employees</li>
            <li>May have odd working hours</li>
            <li>May not belong to a group or association</li>
            <li>May not have permanent work address</li>
            <li>And their businesses may be seasonal</li>
          </ul>
          <p>
            The other goal of the PTMS is to merge the taxpayer records with any
            of the parent body tax net. This ensure that the PTMS records are
            not orphan database. These also give the government a better view of
            who is what and where.
          </p>
          <p>
            Also since neo4j is the principal database of the system, the system
            periodical perform operation on the data, such operation includes:
          </p>
          <ul>
            <li>
              Taxpayers identity verification to reduce fraud in the enumeration
            </li>
            <li>Grouping taxpayers for Best of Judgment(BOJ) assessment</li>
            <li>
              To promote taxpayers whose business is above that of presumptive
              taxation to pay proper tax
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import PartnershipHero from "@/components/PartnershipHero";
export default {};
</script>
