import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    taxpayer: {
      taxpayerId: null,
      fullNames: null,
      remittancePeriod: null,
      taxPeriod: null,
      taxLiability: null,
      businessCategory: null,
      phone: null,
    },
    admin: {},
  },
  getters: {
    tp_taxpayerId: (state) => {
      return state.taxpayer.taxpayerId;
    },
    tp_remittancePeriod: (state) => {
      return state.taxpayer.remittancePeriod;
    },
    tp_taxPeriod: (state) => {
      return state.taxpayer.taxPeriod;
    },
    tp_taxLiability: (state) => {
      return state.taxpayer.taxLiability;
    },
    tp_businessCategory: (state) => {
      return state.taxpayer.businessCategory;
    },
    tp_phone: (state) => {
      return state.taxpayer.phone;
    },
  },
  mutations: {
    UPDATE_TAXPAYER: (state, tp) => {
      state.taxpayer.taxpayerId = tp.taxpayerId;
      state.taxpayer.fullNames = tp.fullNames;
      state.taxpayer.remittancePeriod = tp.remittancePeriod;
      state.taxpayer.taxPeriod = tp.taxPeriod;
      state.taxpayer.taxLiability = tp.taxLiability;
      state.taxpayer.businessCategory = tp.businessCategory;
      state.taxpayer.phone = tp.phone;
    },
  },
  actions: {},
  modules: {},
});
