<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-img contain :src="require('@/assets/fctris.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="font-weight-medium text-h5">
          Aims and objectives of the project
        </div>
        <div class="py-5">
          <p>
            The aim of this project is to explore areas not currently been used
            by government in the FCT to grow their tax net and increase revenue:
          </p>
          <ul>
            <li>The inclusion of presumptive taxpayers into the tax net</li>
            <li>
              The use of graph database(Neo4J) to capture them so that:
              <ol>
                <li>Fraudulent/duplicated entries can be minimized</li>
                <li>
                  Entries can be harmonies with existing taxpayers list (where
                  they exist)
                </li>
                <li>Entries can be grouped and Patterns can be detected</li>
              </ol>
            </li>
            <li>
              The GPS coordinate will help to give the addresses and location of
              the SMEs (taxpayers)
            </li>
            <li>
              The use of payment gateways/mobile network operators to deduct tax
              liabilities
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import PartnershipHero from "@/components/PartnershipHero";
export default {};
</script>
