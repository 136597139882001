import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Aims from "../views/Aims.vue";
import About from "../views/About.vue";
//import Register from "../views/Register.vue";
//import Login from "../views/Login.vue";
import LoginTaxpayer from "../views/LoginTaxpayer.vue";

//Taxpayer Files
import TaxpayerDashboard from "../views/TaxpayerDashboard.vue";
import TaxpayerProfile from "../views/TaxpayerProfile.vue";
import TaxpayerAssessmentPeriod from "../views/TaxpayerAssessmentPeriod.vue";
//import TaxpayerTaxLiability from "../views/TaxpayerTaxLiability.vue";
import TaxpayerLedger from "../views/TaxpayerLedger.vue";

//Admin Files
import AdminChangeRequest from "../views/AdminChangeRequest.vue";
import AdminDashboard from "../views/AdminDashboard.vue";
import AdminPayments from "../views/AdminPayments.vue";
import AdminDuplicate from "../views/AdminDuplicate.vue";

//Other Components
import SiteFooter from "../components/SiteFooter.vue";
import TaxpayerFooter from "../components/TaxpayerFooter.vue";
import SiteNavbar from "../components/SiteNavbar.vue";
import TaxpayerNavbar from "../components/TaxpayerNavbar";
import EmptyNavbar from "../components/EmptyNavbar";
import AdminNavbar from "../components/AdminNavbar";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      navbar: SiteNavbar,
      footer: SiteFooter,
    },
  },
  {
    path: "/about",
    name: "About",
    components: {
      default: About,
      navbar: SiteNavbar,
      footer: SiteFooter,
    },
  },
  {
    path: "/aims",
    name: "Aims",
    components: {
      default: Aims,
      navbar: SiteNavbar,
      footer: SiteFooter,
    },
  },
  {
    path: "/login-taxpayer",
    name: "LoginTaxpayer",
    components: {
      default: LoginTaxpayer,
      navbar: EmptyNavbar,
    },
  },
  {
    path: "/taxpayer-dashboard",
    name: "TaxpayerDashboard",
    components: {
      default: TaxpayerDashboard,
      navbar: TaxpayerNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/taxpayer-profile",
    name: "TaxpayerProfile",
    components: {
      default: TaxpayerProfile,
      navbar: TaxpayerNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/taxpayer-assessment",
    name: "TaxpayerAssessmentPeriod",
    components: {
      default: TaxpayerAssessmentPeriod,
      navbar: TaxpayerNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/taxpayer-ledger",
    name: "TaxpayerLedger",
    components: {
      default: TaxpayerLedger,
      navbar: TaxpayerNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/admin-payments",
    name: "AdminPayments",
    components: {
      default: AdminPayments,
      navbar: AdminNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/admin-dashboard",
    name: "AdminDashboard",
    components: {
      default: AdminDashboard,
      navbar: AdminNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/admin-change-request",
    name: "AdminChangeRequest",
    components: {
      default: AdminChangeRequest,
      navbar: AdminNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/admin-duplicate",
    name: "AdminDuplicate",
    components: {
      default: AdminDuplicate,
      navbar: AdminNavbar,
      footer: TaxpayerFooter,
    },
  },
  {
    path: "/neoj",
    beforeEnter() {
      window.location.href = "https://console.neo4j.io";
    },
  },
  // otherwise redirect to home
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
