<template>
  <span>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="blue lighten-2"
      dark
      disable-resize-watcher
    >
      <v-list>
        <template v-for="(item, index) in items">
          <v-list-tile :key="index">
            <v-list-tile-content>
              {{ item.title }}
            </v-list-tile-content>
          </v-list-tile>
          <v-divider :key="`divider-${index}`"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="green lighten-1 yellow--text" dark absolute flat app>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <a href="/">
        <v-img
          class="mx-2"
          max-height="40"
          max-width="40"
          contain
          :src="require('@/assets/fctris.jpg')"
        >
        </v-img
      ></a>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-md-and-down white--text">
        <v-btn text class="white--text" to="/"> Home </v-btn>
        <v-btn text class="white--text" to="/about"> About This Project </v-btn>
        <v-btn text class="white--text" to="/aims">
          <!--<v-icon left>mdi-newspaper</v-icon>-->
          Aims & Objectibes
        </v-btn>
      </v-toolbar-items>
      <v-btn rounded class="yellow--text" to="/login-taxpayer">
        <v-icon left>mdi-account-tie-outline</v-icon>
        Login
      </v-btn>
      <!--<v-btn outlined rounded class="yellow--text mx-1" to="/login">
        <v-icon left>mdi-account-tie-outline</v-icon>
        Login***
      </v-btn>-->
    </v-app-bar>
  </span>
</template>

<script>
export default {
  name: "SiteNavbar",
  data() {
    return {
      appTitle: "Meal Prep",
      drawer: false,
      items: [
        {
          title: "Dashboard",
        },
        {
          title: "Sign In",
        },
        {
          title: "Join",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
