import axios from "axios";

var ip = window.location.hostname;
//window.console.log(ip);
window.console.log("IP:" + ip);
var baseUrl = "";
if (ip == "localhost") {
  baseUrl = "http://localhost:81/";
} else {
  baseUrl = "https://ptms-api.domain.nfmp.gov.ng/";
}
export const HTTP = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: "Bearer {token}",
    //headers: { "content-type": "application/x-www-form-urlencoded" },
  },
});
