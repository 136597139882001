<template>
  <v-footer color="green lighten-1 yellow--text" dark apppadless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="item in links"
        :key="item.title"
        :to="item.to"
        color="yellow"
        text
        rounded
        class="my-2"
      >
        {{ item.title }}
      </v-btn>
      <v-col class="text-center white--text" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Presumptive Tax Management System</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    links: [
      {
        title: "Admin Portal",
        to: "/admin-dashboard",
      },
      {
        title: "Neo4J Dashboard",
        to: "/neo4j",
      },
    ],
  }),
};
</script>
<style></style>
