<template>
  <div class="blue darken-1"></div>
  <!--
  <v-app-bar elevation="1" class="blue darken-1" app>
  </v-app-bar>
  -->
</template>

<script>
export default {
  //name: "SiteNavBar"
  data: () => ({}),
};
</script>
