<template>
  <v-container fluid class="">
    <v-row class="pa-5">
      <v-col cols="12" xs="12" md="4">
        <v-select
          v-model="form.assesssmentPeriod"
          :items="options.assesssmentPeriod"
          :rules="rules.select"
          label="Assesssment Period"
          required
        >
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { HTTP } from "../utilities/http-common";
export default {
  data: () => ({
    errors: [],
    form: {
      assesssmentPeriod: "",
    },
    options: {
      assesssmentPeriod: ["Female", "Male"],
    },
    rules: {
      select: [(v) => !!v || "Your option is required"],
    },
  }),
};
</script>
