<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <div class="font-weight-medium text-h5">Tax Ledger</div>
        <div class="">
          <P>Below is of your tax ledger </P>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <template>
          <v-data-table
            :headers="headers"
            :items="table_data"
            :items-per-page="15"
            class="elevation-1"
            ><template slot="body.append">
              <tr>
                <th>Total</th>
                <th class="title">{{ sumField("liability") }}</th>
                <th class="title">{{ sumField("amount") }}</th>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HTTP } from "../utilities/http-common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      profile_info: [],
      headers: [
        {
          text: "Date (Month-Year)",
          align: "start",
          sortable: false,
          value: "liability_date",
        },
        { text: "Liability", value: "liability" },
        { text: "Payment", value: "amount" },
      ],
      table_data: [],
    };
  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.table_data.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
  beforeMount() {
    HTTP.get("/ptms/v1.0/taxpayer/taxledger/" + this.tp_taxpayerId)
      .then((response) => {
        this.table_data = response.data;
        //window.console.log(response.data);
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapGetters(["tp_taxpayerId"]),
  },
};
</script>
