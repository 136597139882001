<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-alert
          shaped
          prominent
          color="green lighten-1 white--text"
          icon="mdi-clipboard-text-clock-outline"
          border="left"
        >
          <h3 class="text-h5">Tax Assestment Period</h3>
          Your current tax remittance period is {{ tp_taxPeriod }}
          <v-divider class="my-4 white" style="opacity: 0.22"></v-divider>
          <v-btn color="white" outlined @click.stop="dialog1 = true"
            >Change Assessment Period</v-btn
          >
          <v-dialog v-model="dialog1" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">
                Changing Assestment Period
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form1" v-model="valid1" lazy-validation>
                    <v-row>
                      <v-col cols="12" xs="12" md="6">
                        <v-select
                          v-model="form.period"
                          :items="options.periods"
                          :rules="rules.select"
                          label="Tax Remittance Period*"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="12">
                        <v-textarea
                          v-model="form.reason_for_change1"
                          clearable
                          autoGrow
                          :rules="rules.reason_for_change"
                          rows="2"
                          label="Reason(s) for change*"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    dialog1 = false;
                    form.reason_for_change1 = null;
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  :loading="loading1"
                  :disabled="loading1"
                  @click="validate1"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="4">
        <v-alert
          shaped
          prominent
          color="green lighten-1 white--text"
          icon="mdi-calculator"
          border="left"
        >
          <h3 class="text-h5">Tax Liability</h3>
          Your current tax liability is {{ tp_taxLiability }} and it be be
          reducted {{ tp_taxPeriod }}

          <v-divider class="my-4 white" style="opacity: 0.22"></v-divider>
          <v-btn color="white" @click.stop="dialog2 = true" outlined
            >Send a complaint
          </v-btn>
          <v-dialog v-model="dialog2" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">
                Changing Tax Liability
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form2" v-model="valid2" lazy-validation>
                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-textarea
                          v-model="form.reason_for_change2"
                          clearable
                          autoGrow
                          :rules="rules.reason_for_change"
                          rows="2"
                          label="Reason(s) for change*"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    dialog2 = false;
                    form.reason_for_change2 = null;
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  :loading="loading2"
                  :disabled="loading2"
                  @click="validate2"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="4"
        ><v-alert
          shaped
          prominent
          color="green lighten-1 white--text"
          icon="mdi-card-account-details-outline"
          border="left"
        >
          <h3 class="text-h6">Business Category</h3>
          Category: {{ tp_businessCategory }} <br />Phone number: {{ tp_phone }}
          <v-divider class="my-4 white" style="opacity: 0.22"></v-divider>
          <v-btn color="white" outlined @click.stop="dialog3 = true"
            >Send a complaint
          </v-btn>
          <v-dialog v-model="dialog3" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">
                Changing Business Category
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form3" v-model="valid3" lazy-validation>
                    <v-row>
                      <v-col cols="12" xs="12" md="6">
                        <v-select
                          v-model="form.business"
                          :items="options.businesses"
                          :rules="rules.select"
                          label="Business*"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="12">
                        <v-textarea
                          v-model="form.reason_for_change3"
                          clearable
                          autoGrow
                          :rules="rules.reason_for_change"
                          rows="2"
                          label="Reason(s) for change*"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    dialog3 = false;
                    form.reason_for_change3 = null;
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="green darken-1"
                  :loading="loading3"
                  :disabled="loading3"
                  @click="validate3"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <div class="font-weight-medium text-h5">
          Presumptive Tax Manegement System
        </div>
        <div class="py-5">
          <P
            >Your tax liability is calculated once a year and tax remittance
            kicks is collect periodically either in

            <ul>
              <li>Quarterly</li>
              <li>Bimonthly</li>
              <li>Or monthly</li>
            </ul>
          </P>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HTTP } from "../utilities/http-common";
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    errors: [],
    dialog1: false,
    dialog2: false,
    dialog3: false,
    loading1: false,
    loading2: false,
    loading3: false,
    form: {
      business: null,
      location: null,
      period: null,
      reason_for_change1: null,
      reason_for_change2: null,
      reason_for_change3: null,
    },
    valid1: true,
    valid2: true,
    valid3: true,
    options: {
      businesses: [],
      towns: [],
      periods: [],
    },
    rules: {
      Reason_for_change: [
        (v) => !!v || "This field is required",
        (v) =>
          (v && v.length >= 1) ||
          "This field must be greater than 20 characters long",
      ],
      select: [(v) => !!v || "Your option is required"],
    },
  }),
  beforeMount() {
    HTTP.get("/ptms/v1.0/options/dashboard")
      .then((response) => {
        response.data[0]["businesses"].forEach((item) => {
          this.options.businesses.push(item.business);
        });
        response.data[0]["periods"].forEach((item) => {
          this.options.periods.push(item.period);
        });
        response.data[0]["towns"].forEach((item) => {
          this.options.towns.push(item.town);
        });
        this.form.period = this.tp_taxPeriod;
        this.form.business = this.tp_businessCategory;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapGetters([
      "tp_taxpayerId",
      "tp_taxLiability",
      "tp_taxPeriod",
      "tp_businessCategory",
      "tp_phone",
    ]),
  },
  methods: {
    ...mapMutations(["ADD_TAXPAYER"]),
    validate1() {
      if (this.$refs.form1.validate()) {
        this.loading1 = true;
        const formData = new FormData();
        formData.append("requestType", "Remittance Period");
        formData.append("oldValue", this.tp_taxPeriod);
        formData.append("requestedValue", this.form.period);
        formData.append("taxpayerId", this.tp_taxpayerId);
        formData.append("reasonsForChange", this.form.reason_for_change1);
        HTTP.post("/ptms/v1.0/taxpayer/changeRequest", formData)
          .then((response) => {
            this.loading1 = false;
            if (response.data.message === "done") {
              alert("done");
            } else {
              //this.loginError = true;
            }
          })
          .catch((e) => {
            this.loading1 = false;
            //this.loginError3 = true;
            this.errors.push(e);
          });
      }
    },
    validate2() {
      if (this.$refs.form2.validate()) {
        this.loading2 = true;
        const formData = new FormData();
        formData.append("businessCategory", this.tp_businessCategory);
        formData.append("taxpayerId", this.tp_taxpayerId);
        formData.append("reason_for_change", this.form.reason_for_change1);
        HTTP.post("/ptms/v1.0/taxpayer/businessCategory", formData)
          .then((response) => {
            this.loading2 = false;
            if (response.data.status === "OK") {
              window.console.log("OK");
            } else {
              //this.loginError = true;
            }
          })
          .catch((e) => {
            this.loading2 = false;
            //this.loginError3 = true;
            this.errors.push(e);
          });
      }
    },
    validate3() {
      if (this.$refs.form3.validate()) {
        this.loading3 = true;
        const formData = new FormData();
        formData.append("businessCategory", this.tp_businessCategory);
        formData.append("taxpayerId", this.tp_taxpayerId);
        formData.append("reason_for_change", this.form.reason_for_change3);
        HTTP.post("/ptms/v1.0/taxpayer/businessCategory", formData)
          .then((response) => {
            this.loading3 = false;
            if (response.data.status === "OK") {
              /*var taxpayer = {
                taxpayerId: response.data.taxpayerId,
                fullNames: response.data.fullNames,
                remittancePeriod: response.data.remittance_period,
                phone: response.data.phone,
              };*/
              window.console.log("OK");
              //this.update_taxpayer(taxpayer);
            } else {
              //this.loginError = true;
            }
          })
          .catch((e) => {
            this.loading3 = false;
            //this.loginError3 = true;
            this.errors.push(e);
          });
      }
    },
  },
};
</script>
